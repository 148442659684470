import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './bigBadLoader.style.scss';
import { appState } from '@state/index';
import BackgroundAnimationComponent from './backgroundAnimation/backgroundAnimation.component';
import TextTransitionComponent from '@components/textTransition/textTransition.component';
const isServer = !(typeof window !== 'undefined');
let Checkmark = () => { return null; };
if (!isServer) {
    Checkmark = require('react-checkmark').Checkmark;
}

export type Props = {

};
export type State = {
    stage: number;
};
@observer
export default class BigBadLoaderComponent extends Component<Props, State> {
    state: State = {
        stage: 0,
    };

    inteval: any = null;

    incStage = () => {
        this.setState({ stage: this.state.stage + 1 });
        if (this.state.stage > 3) {
            appState.onFinishProcessing();
        }
    }
    componentDidMount() {
        this.inteval = setInterval(() => {
            this.incStage();
        }, 2000);
    }
    componentWillUnmount() {
        if (this.inteval) {
            clearInterval(this.inteval);
        }
    }
    render() {
        const { stage } = this.state;
        return (
            <div className={style.container}>
                <div className={style.animationContainer}>
                    <BackgroundAnimationComponent />
                </div>
                <div className={style.content}>
                    <div className={style.titleContainer}>
                        <div className={style.title}>Just a second...</div>
                        <div className={style.subtitle}>We are preparing for you the investment proposal</div>
                    </div>
                    <div className={style.progress}>
                        {stage > 0 ?
                            <div className={style.animatedTextContainer}>
                                <div className={style.checkmark}>
                                    <Checkmark size={30} />
                                </div>
                                <TextTransitionComponent className={style.animatedText} inline={false} direction={'up'} springConfig={'wobbly'} text={'Preparing your file'}></TextTransitionComponent>
                            </div> : null
                        }
                        {
                            stage > 1 ?
                                <div className={style.animatedTextContainer}>
                                    <div className={style.checkmark}>
                                        <Checkmark size={30} />
                                    </div>
                                    <TextTransitionComponent className={style.animatedText} inline={false} direction={'up'} springConfig={'wobbly'} text={'Estimating your risk'}></TextTransitionComponent>
                                </div>
                                : null}
                        {
                            stage > 2 ?
                                <div className={style.animatedTextContainer}>
                                    <div className={style.checkmark}>
                                        <Checkmark size={30} />
                                    </div>
                                    <TextTransitionComponent className={style.animatedText} inline={false} direction={'up'} springConfig={'wobbly'} text={'Collecting Recommendations'}></TextTransitionComponent>
                                </div>
                                : null}
                    </div>
                </div>
            </div>
        );
    }
}
