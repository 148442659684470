import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './stageLiquid.style.scss';
import { appState } from '@state/index';
import StageCardLayoutComponent from '@components/stageCardLayout/stageCardLayout.component';
import OptionsSelectorComponent, { OptionItem } from '@components/optionsSelector/optionsSelector.component';

const options: OptionItem[] = [
    { key: '<10%', value: 'Up to 10%' },
    { key: '10%-25%', value: '10-25%' },
    { key: '25%-50%', value: '25-50%' },
    { key: '50%-75%', value: '50-75%' },
    { key: '>95%', value: 'Up to 100%' },
];
export type Props = {
    stageIndex: number;

};
@observer
export default class StageLiquidComponent extends Component<Props> {
    validateStage = () => {
        return !!appState.userData.investmentGoal;
    }
    renderMoreInfo = () => (
        <Fragment>
            The amount of your liquid assets that you are willing to invest with us from your total assets will aid us in determining an appropriate risk level for you. For example,
            if you intend to invest a significant proportion of your total assets, we would like to adjust your investments to reflect a relatively lower level of risk.
        </Fragment>
    )

    getVerticalOptionByKey(key: string) {
        return options.find(x => x.key === key);
    }
    updateLiquid(option: OptionItem) {
        appState.userData.investmentLiquid = option.key;
    }
    render() {
        return (
            <StageCardLayoutComponent
                stageIndex={this.props.stageIndex}
                validateStage={this.validateStage}
                title={'What is the amount of your liquid assets that you intend to invest?'}
                subtitle={'Could be cash deposits, financial assets, securities and similar.'}
                renderMoreInfo={this.renderMoreInfo}
            >
                <OptionsSelectorComponent
                    type={'vertical'}
                    options={options}
                    onChange={this.updateLiquid}
                    value={this.getVerticalOptionByKey(appState.userData.investmentLiquid)}
                />
            </StageCardLayoutComponent>
        );
    }
}
