import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './stageRisk.style.scss';
import { appState } from '@state/index';
import StageCardLayoutComponent from '@components/stageCardLayout/stageCardLayout.component';
import OptionsSelectorComponent, { OptionItem } from '@components/optionsSelector/optionsSelector.component';

const options: OptionItem[] = [
    { key: '4', value: 'Not agree at all' },
    { key: '3', value: 'Not agree' },
    { key: '2', value: 'Agree' },
    { key: '1', value: 'Very Agree' },
];
export type Props = {
    stageIndex: number;

};
@observer
export default class StageRiskComponent extends Component<Props> {
    validateStage = () => {
        return !!appState.userData.investmentRisk;
    }
    renderMoreInfo = () => (
        <Fragment>
            This will allow us to better understand your attitude towards risk.
            For our purposes this is a highly important factor for us to adapt a portfolio that is right for you.
            It’s recommended to invest in channels that permit relatively high long-term returns. These paths are volatile in short term time frames.
            Fluctuations in the market at times lead investors to impulsively pull their assets. This is not necessarily the case for long term portfolios.
        </Fragment>
    )

    getVerticalOptionByKey(key: string) {
        return options.find(x => x.key === key);
    }
    updateData(option: OptionItem) {
        appState.userData.investmentRisk = option.key;
    }
    render() {
        return (
            <StageCardLayoutComponent
                stageIndex={this.props.stageIndex}
                validateStage={this.validateStage}
                title={'How much do you agree with the following statement?'}
                subtitle={'"I prefer a high risk investment than a low risk investment” With 1 being don’t agree at all, and 5 being completely agree."'}
                renderMoreInfo={this.renderMoreInfo}
            >
                <OptionsSelectorComponent
                    type={'vertical'}
                    options={options}
                    onChange={this.updateData}
                    value={this.getVerticalOptionByKey(appState.userData.investmentRisk)}
                />
            </StageCardLayoutComponent>
        );
    }
}
