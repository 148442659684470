import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import { appState } from '@state/index';
import anime from 'animejs/lib/anime.es.js';
require('./backgorundAnimation.style.css');

export type Props = {

};
@observer
export default class BackgroundAnimationComponent extends Component<Props> {
    fitElementToParent(el, padding?) {
        let timeout = null;
        function resize() {
          if (timeout) clearTimeout(timeout);
          anime.set(el, { scale: 1 });
          const pad = padding || 0;
          const parentEl = el.parentNode;
          const elOffsetWidth = el.offsetWidth - pad;
          const parentOffsetWidth = parentEl.offsetWidth;
          const ratio = parentOffsetWidth / elOffsetWidth;
          timeout = setTimeout(anime.set(el, { scale: ratio }), 10);
        }
        resize();
        window.addEventListener('resize', resize);
    }

    componentDidMount() {
          const layeredAnimation = (() => {

            const transformEls = document.querySelectorAll('.transform-progress');
            const layeredAnimationEl = document.querySelector('.layered-animations');
            const shapeEls = layeredAnimationEl.querySelectorAll('.shape');
            const triangleEl = layeredAnimationEl.querySelector('polygon');
            const trianglePoints = triangleEl.getAttribute('points').split(' ');
            const easings = ['easeInOutQuad', 'easeInOutCirc', 'easeInOutSine', 'spring'];

            this.fitElementToParent(layeredAnimationEl);

            function createKeyframes(value) {
              const keyframes = [];
              for (let i = 0; i < 30; i++) keyframes.push({ value });
              return keyframes;
            }

            function animateShape(el) {

              const circleEl = el.querySelector('circle');
              const rectEl = el.querySelector('rect');
              const polyEl = el.querySelector('polygon');

              const animation = anime.timeline({
                targets: el,
                duration() { return anime.random(600, 2200); },
                easing() { return easings[anime.random(0, easings.length - 1)]; },
                complete(anim) { animateShape(anim.animatables[0].target); },
              })
              .add({
                translateX: createKeyframes((el) => {
                  return el.classList.contains('large') ? anime.random(-300, 300) : anime.random(-520, 520);
                }),
                translateY: createKeyframes((el) => {
                  return el.classList.contains('large') ? anime.random(-110, 110) : anime.random(-280, 280);
                }),
                rotate: createKeyframes(() => { return anime.random(-180, 180); }),
              }, 0);
              if (circleEl) {
                animation.add({
                  targets: circleEl,
                  r: createKeyframes(() => { return anime.random(32, 72); }),
                }, 0);
              }
              if (rectEl) {
                animation.add({
                  targets: rectEl,
                  width: createKeyframes(() => { return anime.random(64, 120); }),
                  height: createKeyframes(() => { return anime.random(64, 120); }),
                }, 0);
              }
              if (polyEl) {
                animation.add({
                  targets: polyEl,
                  points: createKeyframes(() => {
                    const scale = anime.random(72, 180) / 100;
                    return trianglePoints.map((p) => { return (p * scale); }).join(' ');
                  }),
                }, 0);
              }

            }
            for (let i = 0; i < shapeEls.length; i++) {
              animateShape(shapeEls[i]);
            }

          })();
    }
    render() {
        return (
            <div className="animation-wrapper">
                <div className="layered-animations">
                    <svg className="large shape" viewBox="0 0 96 96">
                        <defs>
                            <linearGradient id="circleGradient" x1="0%" x2="100%" y1="20%" y2="80%">
                                <stop stopColor="#373734" offset="0%" />
                                <stop stopColor="#242423" offset="50%" />
                                <stop stopColor="#0D0D0C" offset="100%" />
                            </linearGradient>
                        </defs>
                        <circle cx="48" cy="48" r="28" fillRule="evenodd" strokeLinecap="square" fill="url(#circleGradient)" />
                    </svg>
                    <svg className="small shape color-red" viewBox="0 0 96 96">
                        <polygon fillRule="evenodd" points="48 17.28 86.4 80.11584 9.6 80.11584" strokeLinecap="square" />
                    </svg>
                    <svg className="large shape" viewBox="0 0 96 96">
                        <defs>
                            <linearGradient id="triangleGradient" x1="0%" x2="100%" y1="20%" y2="80%">
                                <stop stopColor="#373734" offset="0%" />
                                <stop stopColor="#242423" offset="50%" />
                                <stop stopColor="#0D0D0C" offset="100%" />
                            </linearGradient>
                        </defs>
                        <polygon fillRule="evenodd" points="48 17.28 86.4 80.11584 9.6 80.11584" strokeLinecap="square" fill="url(#triangleGradient)" />
                    </svg>
                    <svg className="x-small shape" viewBox="0 0 96 96">
                        <polygon fillRule="evenodd" points="48 17.28 86.4 80.11584 9.6 80.11584" strokeLinecap="square" />
                    </svg>
                    <svg className="x-small shape" viewBox="0 0 96 96">
                        <rect width="48" height="48" x="24" y="24" fillRule="evenodd" strokeLinecap="square" />
                    </svg>
                    <svg className="small shape color-red" viewBox="0 0 96 96">
                        <rect width="48" height="48" x="24" y="24" fillRule="evenodd" strokeLinecap="square" />
                    </svg>
                    <svg className="large shape" viewBox="0 0 96 96">
                        <defs>
                            <linearGradient id="rectGradient" x1="0%" x2="100%" y1="20%" y2="80%">
                                <stop stopColor="#373734" offset="0%" />
                                <stop stopColor="#242423" offset="50%" />
                                <stop stopColor="#0D0D0C" offset="100%" />
                            </linearGradient>
                        </defs>
                        <rect width="48" height="48" x="24" y="24" fillRule="evenodd" strokeLinecap="square" fill="url(#rectGradient)" />
                    </svg>
                    <svg className="small shape color-red" viewBox="0 0 96 96">
                        <circle cx="48" cy="48" r="32" fillRule="evenodd" strokeLinecap="square" />
                    </svg>
                    <svg className="x-small shape" viewBox="0 0 96 96">
                        <circle cx="48" cy="48" r="32" fillRule="evenodd" strokeLinecap="square" />
                    </svg>
                </div>
            </div>
        );
    }
}
