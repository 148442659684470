import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './stageInvestmentGoal.style.scss';
import { appState } from '@state/index';
import StageCardLayoutComponent from '@components/stageCardLayout/stageCardLayout.component';
import OptionsSelectorComponent, { OptionItem } from '@components/optionsSelector/optionsSelector.component';

const options: OptionItem[] = [
    { key: 'no-goal', value: 'I currently have no goal' },
    { key: 'specific-goal', value: 'Specific purpose (for example buying an apartment, wedding etc.)' },
    { key: 'pension-goal', value: 'Setting up funds for pension' },
    { key: 'other', value: 'Other' },
];
export type Props = {
    stageIndex: number;

};
@observer
export default class StageInvestmentGoalComponent extends Component<Props> {
    validateStage = () => {
        return !!appState.userData.investmentGoal;
    }
    renderMoreInfo = () => (
        <Fragment>
            When you have variable investment goals,
            this allows you to have different investment behaviour at different time periods.
            Like we mentioned before, the longer your investment horizon theOther better!
        </Fragment>
    )

    getVerticalOptionByKey(key: string) {
        return options.find(x => x.key === key);
    }
    updateData(option: OptionItem) {
        appState.userData.investmentGoal = option.key;
    }
    render() {
        return (
            <StageCardLayoutComponent
                stageIndex={this.props.stageIndex}
                validateStage={this.validateStage}
                title={'What is the main purpose for your investment?'}
                subtitle={''}
                renderMoreInfo={this.renderMoreInfo}
            >
                <OptionsSelectorComponent
                    type={'vertical'}
                    options={options}
                    onChange={this.updateData}
                    value={this.getVerticalOptionByKey(appState.userData.investmentGoal)}
                />
            </StageCardLayoutComponent>
        );
    }
}
