import React, { Component } from 'react';
import cls from 'classnames';
import style from './continueCta.style.scss';

export type Props = {
    isDisabled: boolean;
    text: string;
    onClick?: () => any;
    autoClickTimeout?: number;
};
export default class ContinueCtaComponent extends Component<Props> {
    timeout: any;
    onClick = () => {
        if (!this.props.isDisabled) {
            this.props.onClick?.();
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
        }
    }

    componentDidMount() {
        if (!this.props.isDisabled && this.props.autoClickTimeout) {
            this.timeout = setTimeout(() => {
                this.onClick();
            }, this.props.autoClickTimeout * 1000);
        }
    }

    render() {
        const {  isDisabled } = this.props;
        return (
            <div className={style.container}>
                <button
                    onClick={this.onClick}
                    className={cls(style.button, { [style.disabled]: isDisabled, [style.enabled]: !isDisabled })}>
                    { this.props.text }
                </button>
                { this.props.autoClickTimeout ? <div className={style.progress} style={{ animationDuration: `${this.props.autoClickTimeout}s` }}></div> : null }
            </div>
        );
    }
}
