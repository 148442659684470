import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './stageDurable.style.scss';
import { appState } from '@state/index';
import StageCardLayoutComponent from '@components/stageCardLayout/stageCardLayout.component';
import OptionsSelectorComponent, { OptionItem } from '@components/optionsSelector/optionsSelector.component';

const options: OptionItem[] = [
    { key: '1-5%', value: '1-5%' },
    { key: '5-10%', value: '5-10%' },
    { key: '10-20%', value: '10-20%' },
    { key: '20-40%', value: '20-40%' },
    { key: '40-50%', value: '40-50%' },
];
export type Props = {
    stageIndex: number;

};
@observer
export default class StageDurableComponent extends Component<Props> {
    validateStage = () => {
        return !!appState.userData.investmentDurable;
    }
    renderMoreInfo = () => (
        <Fragment>
            As in the previous questions, it is important for us to understand your risk-taking approach, as we have a portfolio that has the potential for maximum return over time, but will also make you comfortable.
            <br/><br/>
            In this question we ask about your ability to deal with a temporary decrease in the value of your portfolio. For example, if your portfolio size is NIS 100,000, choosing a 20% answer means you think you will be able to handle a temporary decrease of NIS 20,000 in portfolio value.
            <br/><br/>
            It is true that risk exposure cannot be avoided, but the desired risk level can certainly be chosen according to the preferences and nature of each investor.
        </Fragment>
    )

    getVerticalOptionByKey(key: string) {
        return options.find(x => x.key === key);
    }
    updateData(option: OptionItem) {
        appState.userData.investmentDurable = option.key;
    }
    render() {
        return (
            <StageCardLayoutComponent
                stageIndex={this.props.stageIndex}
                validateStage={this.validateStage}
                title={'For the purpose of gaining long term profit from my portfolio, I can deal with the following decline in the value of my portfolio in the case of market fluctuations'}
                subtitle={''}
                renderMoreInfo={this.renderMoreInfo}
            >
                <OptionsSelectorComponent
                    type={'horizontal'}
                    options={options}
                    onChange={this.updateData}
                    value={this.getVerticalOptionByKey(appState.userData.investmentDurable)}
                />
            </StageCardLayoutComponent>
        );
    }
}
