import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './mobileContinueCta.style.scss';
import { appState } from '@state/index';
import { prevIcon, nextIcon } from './assets';

export type Props = {
    text: string;
    isNextDisabled: boolean;
    isPrevDisabled: boolean;
    shouldShowPrev: boolean;
    onNext: () => any;
    onPrev: () => any;
    autoClickTimeout?: number
};
@observer
export default class MobileContinueCtaComponent extends Component<Props> {
    timeout: any;
    onPrev = () => {
        if (!this.props.isPrevDisabled) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.props.onPrev();
        }
    }
    onNext = () => {
        if (!this.props.isNextDisabled) {
            this.props.onNext?.();
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
        }
    }

    componentDidMount() {
        if (!this.props.isNextDisabled && this.props.autoClickTimeout) {
            this.timeout = setTimeout(() => {
                this.onNext();
            }, this.props.autoClickTimeout * 1000);
        }
    }

    render() {
        const { text, isNextDisabled, isPrevDisabled, shouldShowPrev } = this.props;
        return (
            <div className={cls(style.container, { [style.prevHidden]: !shouldShowPrev })}>
                {shouldShowPrev ? (
                    <div className={cls(style.prevButtonContainer, { [style.disabled]: isPrevDisabled })} onClick={this.onPrev}>
                        <img className={style.prevButtonImage} src={prevIcon} />
                    </div>
                ) : null}
                <div className={cls(style.nextButtonContainer, { [style.disabled]: isNextDisabled })} onClick={this.onNext}>
                    <div className={style.nextButton}>
                        <div className={style.nextText}>{text}</div>
                        <img className={style.nextImage} src={nextIcon} />
                    </div>
                </div>
                {this.props.autoClickTimeout ? <div className={style.progress} style={{ animationDuration: `${this.props.autoClickTimeout}s` }}></div> : null}
            </div>
        );
    }
}
