import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './stageCrisis.style.scss';
import { appState } from '@state/index';
import StageCardLayoutComponent from '@components/stageCardLayout/stageCardLayout.component';
import OptionsSelectorComponent, { OptionItem } from '@components/optionsSelector/optionsSelector.component';

const options: OptionItem[] = [
    { key: 'no-file', value: 'Give up on the portfolio' },
    { key: 'partial-file', value: 'Sell some assets in the portfolio' },
    { key: 'no-change', value: 'Do nothing' },
    { key: 'increase-file', value: 'Increase investment amount' },
];
export type Props = {
    stageIndex: number;

};
@observer
export default class StageCrisisComponent extends Component<Props> {
    validateStage = () => {
        return !!appState.userData.investmentCrisis;
    }
    renderMoreInfo = () => (
        <Fragment>
            Like the previous question this will allow us to better understand your attitude towards risk.
        </Fragment>
    )

    getVerticalOptionByKey(key: string) {
        return options.find(x => x.key === key);
    }
    updateData(option: OptionItem) {
        appState.userData.investmentCrisis = option.key;
    }
    render() {
        return (
            <StageCardLayoutComponent
                stageIndex={this.props.stageIndex}
                validateStage={this.validateStage}
                title={'What do you think would be the right move in the case that your portfolio’s value drops by 20% over a month period due to a capital market crisis?'}
                subtitle={''}
                renderMoreInfo={this.renderMoreInfo}
            >
                <OptionsSelectorComponent
                    type={'vertical'}
                    options={options}
                    onChange={this.updateData}
                    value={this.getVerticalOptionByKey(appState.userData.investmentCrisis)}
                />
            </StageCardLayoutComponent>
        );
    }
}
