import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './stageWelcome.style.scss';
import { appState } from '@state/index';
import StageCardLayoutComponent from '@components/stageCardLayout/stageCardLayout.component';

export type Props = {
    stageIndex: number;

};
@observer
export default class StageWelcomeComponent extends Component<Props> {
    validateStage = () => {
        return true;
    }
    render() {
        return (
            <StageCardLayoutComponent
                stageIndex={this.props.stageIndex}
                validateStage={this.validateStage}
                continueTimeout={8}
                title={'We have 8 short questions for you, this will only take 5 minutes, and then you will have an investment portfolio proposal!'}
                subtitle={'In order for us to properly prepare the most appropriate investment portfolio, we have a few questions about your preferences.'}
            >
                {/* <div className={style.container}> */}
                    {/* <div className={style.divider}></div> */}
                    {/* <div className={style.content}>
                    If your investment goals are not the same, you may want to consider opening a joint account and contact us if necessary. <br />
                    Have you received a joint summons for the account? Please use the link sent to you
                    </div> */}
                {/* </div> */}
            </StageCardLayoutComponent>
        );
    }
}
