import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './numberInput.style.scss';
import { appState } from '@state/index';

export type Props = {
    label: string;
    onChange: (value: number) => any;
    onBlur?: () => any;
    onFocus?: () => any;
    value: number;
};
export type State = {
    isFocus: boolean;
    value: string;
};
@observer
export default class NumberInputComponent extends Component<Props, State> {
    inputRef: HTMLInputElement;
    state: State = {
        isFocus: false,
        value: '',
    };
    onTextFocus = () => {
        this.setState({ isFocus: true });
        this.props.onFocus?.();
    }
    onTextBlur = () => {
        this.setState({ isFocus: false });
        this.props.onBlur?.();
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        const formatted = new Intl.NumberFormat('he-HE', { style: 'currency', currency: 'ILS' }).format(props.value);
        const x = formatted.split('.')[0];
        const newVal = `$ ${x}`;
        if (!props.value) {
            return { ...state, value: '' };
        } else if (state.value !== newVal) {
            return { ...state, value: newVal };
        } else {
            return null;
        }
    }

    transformToNumber(str: string) {
        const replaced = str.match(/\d/g)?.join('');
        if (replaced) {
            const numbersOnly = Number(replaced);
            return numbersOnly;
        } else {
            return null;
        }

    }
    onKeyDown = (e) => {
        const key = e.key;
        if (!Number.isNaN(Number(key))) {
            const updatedVal = `${this.state.value}${key}`;
            const numberic = this.transformToNumber(updatedVal);
            this.props.onChange(numberic);
        } else if (e.key === 'Backspace') {
            const newValue = Array.from(this.state.value);
            newValue.pop();
            const newValueJoined = newValue.join('');
            this.props.onChange(this.transformToNumber(newValueJoined));
        }
    }
    onLabelClick = () => {
        this.inputRef.focus();
    }
    render() {
        const shouldPutLabelOnTop = this.state.isFocus || this.state.value || this.props.value;
        return (
            <div className={style.container}>
                <div className={style.labelPlaceholder}></div>
                <div className={style.inputContainer}>
                    <input
                        ref={(ref) => this.inputRef = ref}
                        onChange={() => {}}
                        className={style.input}
                        value={this.state.value}
                        onFocus={this.onTextFocus}
                        onBlur={this.onTextBlur}
                        onKeyDown={this.onKeyDown}/>
                    <div
                        onClick={this.onLabelClick}
                        className={cls(style.label, { [style.outInput]: shouldPutLabelOnTop })}>
                        {this.props.label}
                    </div>
                </div>
            </div>
        );
    }
}
