import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './stagesCarusel.style.scss';
import { appState } from '@state/index';
import StageWelcomeComponent from '../../stages/stageWelcome/stageWelcome.component';
import StageAgeComponent from '../../stages/stageAge/stageAge.component';
import StageInvestComponent from '../../stages/stageInvest/stageInvest.component';
import StageInvestmentTypeComponent from '../../stages/stageInvestmentType/stageInvestmentType.component';
import StageInvestmentGoalComponent from '../../stages/stageInvestmentGoal/stageInvestmentGoal.component';
import StageRiskComponent from '../../stages/stageRisk/stageRisk.component';
import StageDurableComponent from '../../stages/stageDurable/stageDurable.component';
import StageLiquidComponent from '../../stages/stageLiquid/stageLiquid.component';
import StageCrisisComponent from '../../stages/stageCrisis/stageCrisis.component';
import { polygons } from './assets';

export type Props = {

};
const stageArr = [
    (idx: number) => (<StageWelcomeComponent stageIndex={idx} />),
    (idx: number) => (<StageAgeComponent stageIndex={idx} />),
    (idx: number) => (<StageInvestComponent stageIndex={idx} />),
    (idx: number) => (<StageInvestmentTypeComponent stageIndex={idx} />),
    (idx: number) => (<StageInvestmentGoalComponent stageIndex={idx} />),
    (idx: number) => (<StageLiquidComponent stageIndex={idx} />),
    (idx: number) => (<StageRiskComponent stageIndex={idx} />),
    (idx: number) => (<StageCrisisComponent stageIndex={idx} />),
    (idx: number) => (<StageDurableComponent stageIndex={idx} />),

];
@observer
export default class StagesCaruselComponent extends Component<Props> {

    componentDidMount() {
        appState.totalStages = stageArr.length;
    }
    getStageStyle(idx) {
        if (idx === appState.currentStageIndex - 1) {
            return style.prev;
        } else if (idx === appState.currentStageIndex + 1) {
            return style.next;
        } else if (idx === appState.currentStageIndex) {
            return style.current;
        } else if (idx > appState.currentStageIndex) {
            return style.hiddenNext;
        } else if (idx < appState.currentStageIndex) {
            return style.hiddenPrev;
        }
    }

    render() {
        return (
            <div className={style.container}>
                <img className={style.polygonsOverlay} src={polygons} />
                {stageArr.map((stage, idx) => (
                    <div key={idx} className={cls(style.stageWrapper, this.getStageStyle(idx))}>
                        {stage(idx)}
                    </div>
                ))}
            </div>
        );
    }
}
