import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './stageInvest.style.scss';
import { appState } from '@state/index';
import StageCardLayoutComponent from '@components/stageCardLayout/stageCardLayout.component';
import NumberInputComponent from '@components/numberInput/numberInput.component';

export type Props = {
    stageIndex: number;
};
@observer
export default class StageInvestComponent extends Component<Props> {
    validateStage = () => {
        return appState.userData.investmentAmt >= 250 && appState.userData.investmentAmt < 500000000;
    }
    moreInfo = () => {
        return (
            <Fragment>The investment amount will reflect how your portfolio’s value is likely to evolve, while taking into account the costs involved.</Fragment>
        );
    }
    onBlur = () => {
        if (!this.validateStage()) {
            appState.stageError = 'A number between 250 and 50,000,000 must be entered';
        }
    }
    onFocus = () => {
        appState.stageError = null;
    }
    render() {
        return (
            <StageCardLayoutComponent
                validateStage={this.validateStage}
                title={'How much money were you thinking of investing?'}
                subtitle={'The investment amount can be easily changed later on if necessary.'}
                stageIndex={this.props.stageIndex}
                renderMoreInfo={this.moreInfo}
                >
            <div className={style.container}>
                <div className={style.inputContainer}>
                    <NumberInputComponent
                        onChange={(val) => appState.userData.investmentAmt = val}
                        value={appState.userData.investmentAmt}
                        label={'My investment'}
                        onBlur={this.onBlur}
                        onFocus={this.onFocus}
                    />
                </div>
                <div className={style.explain}>Minimum 250 $</div>
            </div>
            </StageCardLayoutComponent>
        );
    }
}
