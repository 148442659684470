import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './stageAge.style.scss';
import { appState } from '@state/index';
import StageCardLayoutComponent from '@components/stageCardLayout/stageCardLayout.component';
import SliderComponent from '@components/slider/slider.component';

export type Props = {
    stageIndex: number;
};
export type State = {
    textValue: string;
};
@observer
export default class StageAgeComponent extends Component<Props, State> {
    state: State = {
        textValue: appState.userData.age.toString(),
    };
    validateStage = () => {
        return Number(this.state.textValue) === appState.userData.age;
    }
    moreInfo = () => (
        <div>
            Differing stages in a person’s life will normally dictate and require different approaches when dealing with investments.
            Your age, paired with answers to additional questions will allow us to prepare the optimal investment strategy specifically for you.
            <br />
        </div>
    )

    onAgeChanged = (age: number) => {
        appState.userData.age = age;
        appState.stageError = null;
        this.setState({ textValue: age.toString() });
    }
    onTextAgeChanged = (e) => {
        appState.stageError = null;
        this.setState({ textValue: e.target.value }, () => {
            const numeric = Number(this.state.textValue);
            if (numeric >= 18 && numeric <= 99) {
                appState.userData.age = numeric;
            } else {
                appState.stageError = 'Please type your age between 18-99';
            }
        });
    }
    render() {
        return (
            <StageCardLayoutComponent
                stageIndex={this.props.stageIndex}
                validateStage={this.validateStage}
                title={'How old are you?'}
                subtitle={'It’s usually not so polite to ask, but it’s necessary for us to prepare the most accurate results!'}
                renderMoreInfo={this.moreInfo}
            >
                <div className={style.container}>
                    <div className={style.ageContainer}>
                        <div className={style.ageSlider}>
                            <SliderComponent
                                min={18}
                                max={99}
                                onChange={this.onAgeChanged}
                                value={appState.userData.age}
                                desc={'Slide your age'} />
                        </div>
                        <div className={style.inputContainer}>
                            <div className={style.inputTitle}>My Age</div>
                            <input className={style.inputBox} value={this.state.textValue ?? appState.userData.age} onChange={this.onTextAgeChanged} />
                        </div>
                    </div>
                </div>
            </StageCardLayoutComponent>
        );
    }
}
