import React, { Component, ReactElement, CSSProperties } from 'react';
import cls from 'classnames';
import style from './stageCardLayout.style.scss';
import StageCardComponent from '@components/stageCard/stageCard.component';
import ContinueCtaComponent from '@components/continueCta/continueCta.component';
import { observer } from 'mobx-react';
import { appState } from '@state/index';
import MobileContinueCtaComponent from '@components/mobileContinueCta/mobileContinueCta.component';
export type Props = {
    stageIndex: number;
    validateStage: () => boolean;
    continueTimeout?: number;
    renderMoreInfo?: () => ReactElement;
    title: string;
    subtitle: string;
    customTitle?: CSSProperties;
    CustomSubtitle?: CSSProperties;
};

@observer
export default class StageCardLayoutComponent extends Component<Props> {
    onNext = () => {
        if (this.props.validateStage?.() && !appState.stageError) {
            appState.nextStage();
        }
    }
    onPrev = () => {
        if (!appState.stageError) {
            appState.prevStage();
        }
    }
    render() {
        const ctaText = this.props.stageIndex === 0 ? "Let's go!" : this.props.stageIndex === (appState.totalStages - 1) ? 'Finish' : 'Next Stage';
        const isCurrentStage = appState.currentStageIndex === this.props.stageIndex;
        return (
            <div className={style.container}>
                <div className={style.progress}>
                    { this.props.stageIndex > 0 ? `Step ${this.props.stageIndex}/${appState.totalStages - 1}` : 'Welcome'}
                </div>
                <div className={style.cardContainer}>
                    <StageCardComponent
                        isCurrentCard={isCurrentStage}
                        customTitle={this.props.customTitle}
                        customSubtitle={this.props.CustomSubtitle}
                        title={this.props.title}
                        subtitle={this.props.subtitle}
                        renderMoreInfo={this.props.renderMoreInfo}>
                        {this.props.children}
                    </StageCardComponent>
                </div>
                    <div className={cls(style.ctaContainer, { [style.hideCta]: !isCurrentStage })}>
                        <ContinueCtaComponent
                            text={ctaText}
                            isDisabled={!this.props.validateStage() || !!appState.stageError}
                            onClick={this.onNext}
                            autoClickTimeout={appState.screenWidth >= 450 ? this.props.continueTimeout : null} />
                        {this.props.stageIndex > 1 ? <button className={cls(style.prev, { [style.enabled]: !appState.stageError })} onClick={this.onPrev}>Previous Stage</button> : null}
                    </div>
                <div className={cls(style.mobileCtaContaier, { [style.hideCta]: !isCurrentStage })}>
                    <MobileContinueCtaComponent
                        text={ctaText}
                        shouldShowPrev={this.props.stageIndex > 1}
                        isNextDisabled={!this.props.validateStage() || !!appState.stageError}
                        isPrevDisabled={!!appState.stageError}
                        onNext={this.onNext}
                        onPrev={this.onPrev}
                        autoClickTimeout={appState.screenWidth < 450 ? this.props.continueTimeout : null} />
                </div>
            </div>
        );
    }
}
