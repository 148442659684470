import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './optionsSelector.style.scss';
import { appState } from '@state/index';

export type OptionItem = { key: string, value: string };
export type Props = {
    options: OptionItem[];
    onChange: (option: OptionItem) => any;
    value: OptionItem;
    type: 'vertical' | 'horizontal',
};

@observer
export default class OptionsSelectorComponent extends Component<Props> {
    render() {
        return (
            <Fragment>
                <div className={style.mobile}>
                    <OptionsSelectorInnerComponent
                        onChange={this.props.onChange}
                        options={this.props.options}
                        type={'vertical'}
                        value={this.props.value}
                    />
                </div>
                <div className={style.desktop}>
                    <OptionsSelectorInnerComponent
                        onChange={this.props.onChange}
                        options={this.props.options}
                        type={this.props.type}
                        value={this.props.value}
                    />
                </div>
            </Fragment>
        );
    }
}

@observer
class OptionsSelectorInnerComponent extends Component<Props> {

    selectOption = (option: OptionItem) => {
        this.props.onChange(option);
    }

    render() {
        const isVertical = this.props.type === 'vertical';
        const inlineStyle = { [isVertical ? 'gridTemplateRows' : 'gridTemplateColumns']: `repeat(${this.props.options.length}, 1fr)` };
        const classes = cls(style.bar, { [style.vertical]: isVertical });
        return (
            <div className={classes} style={inlineStyle}>
                {this.props.options.map(x => {
                    if (isVertical) {
                        return (
                            <div className={style.optionRow} key={x.key} onClick={() => this.selectOption(x)}>
                                <div className={style.checkboxContainer}>
                                    <div onClick={() => this.selectOption(x)} className={cls(style.checkbox, { [style.selected]: this.props.value?.key === x.key })}></div>
                                </div>
                                <div className={style.item}>{x.value}</div>
                            </div>
                        );
                    } else {
                        return (
                            <div key={x.key} onClick={() => this.selectOption(x)} className={cls(style.item, { [style.selected]: this.props.value?.key === x.key })}>
                                {x.value}
                            </div>
                        );
                    }
                })}
            </div>
        );
    }
}
