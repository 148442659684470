import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './stageInvestmentType.style.scss';
import { appState } from '@state/index';
import StageCardLayoutComponent from '@components/stageCardLayout/stageCardLayout.component';
import OptionsSelectorComponent, { OptionItem } from '@components/optionsSelector/optionsSelector.component';

const options: OptionItem[] = [
    { key: '1-2years', value: '1-2 Years at most' },
    { key: '3-4years', value: '3-4 Years' },
    { key: '5-10years', value: '5-10 Years' },
    { key: '10-15years', value: '10-15 Years' },
    { key: '15-years', value: '15 Years and above' },
];
export type Props = {
    stageIndex: number;

};
@observer
export default class StageInvestmentTypeComponent extends Component<Props> {
    validateStage = () => {
        return !!appState.userData.investmentType;
    }
    renderMoreInfo = () => (
        <Fragment>
            Normally, longer frames of time will better serve any investment, because it will allow whoever is handling it to better diversify your portfolio into assets with a greater profit-potential in the long term.
            Consider that your investment is liquid and available for full or partial withdrawal, so there is nothing to fear if you set a longer investment period and change your mind along the way.
        </Fragment>
    )

    getVerticalOptionByKey(key: string) {
        return options.find(x => x.key === key);
    }
    updateData(option: OptionItem) {
        appState.userData.investmentType = option.key;
    }
    render() {
        return (
            <StageCardLayoutComponent
                stageIndex={this.props.stageIndex}
                validateStage={this.validateStage}
                title={'According to your estimation, in how many years will you need to use the investment money?'}
                subtitle={'The money can be easily withdrawn at any time, it is therefore wiser and more lucrative to choose a longer time range that suits you.'}
                renderMoreInfo={this.renderMoreInfo}
            >
                <OptionsSelectorComponent
                    type={'horizontal'}
                    options={options}
                    onChange={this.updateData}
                    value={this.getVerticalOptionByKey(appState.userData.investmentType)}
                />
            </StageCardLayoutComponent>
        );
    }
}
