import React, { Component } from 'react';
import cls from 'classnames';
import PageLayoutComponent from '@components/layout/pageLayout.component';
import StagesCaruselComponent from '@components/stagesCarusel/stagesCarusel.component';
import { appState } from '@state/index';
import { observer } from 'mobx-react';
import BigBadLoaderComponent from '@components/bigBadLoader/bigBadLoader.component';

export type Props = {

};
@observer
export default class IndexPage extends Component<Props> {
    render() {
        return (
            <PageLayoutComponent forceHeight={true}>
                { appState.didFinishStages ? <BigBadLoaderComponent /> : (<StagesCaruselComponent />)}
            </PageLayoutComponent>
        );
    }
}
