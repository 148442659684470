import React, { Component, ReactElement, CSSProperties } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './stageCard.style.scss';
import { appState } from '@state/index';
import { downArrowIcon } from './assets';
export type Props = {
    isCurrentCard: boolean;
    renderMoreInfo?: () => ReactElement;
    title: string;
    subtitle: string;
    customTitle?: CSSProperties;
    customSubtitle: CSSProperties;
};
export type State = {
    isMoreInfoOpen: boolean;
};
@observer
export default class StageCardComponent extends Component<Props, State> {
    state: State = {
        isMoreInfoOpen: false,
    };
    onMoreInfo = () => {
        this.setState({ isMoreInfoOpen: !this.state.isMoreInfoOpen });
    }
    render() {
        return (
            <div className={style.container}>
                <div className={style.content}>
                    <div style={this.props.customTitle} className={style.title}>{this.props.title}</div>
                    <div style={this.props.customSubtitle} className={style.subtitle}>{this.props.subtitle}</div>
                    <div className={style.innerContent}>
                        {this.props.children}
                    </div>
                </div>
                {this.props.renderMoreInfo && appState.currentStageIndex !== 8 ? (
                    <div className={cls(style.moreInfoContainer, { [style.open]: this.state.isMoreInfoOpen })}>
                        <div className={style.titleContainer}>
                            <div className={style.openable} onClick={this.onMoreInfo}>
                                <div className={style.imageContainer}>
                                    {/* <img className={style.image} src={moreInfoIcon} /> */}
                                </div>
                                <div className={style.title}>Why do we ask ?</div>
                            </div>
                        </div>
                        <div className={style.content}>
                            <div className={style.info}>
                                {this.props.renderMoreInfo()}
                            </div>
                        </div>
                        <div className={style.close} onClick={this.onMoreInfo}>
                            <div className={style.imageContainer}>
                                <img className={style.image} src={downArrowIcon} />
                            </div>
                        </div>
                    </div>
                ) : null}
                {this.props.isCurrentCard ? (
                    <div className={cls(style.stageError, { [style.stageErrorShow]: appState.stageError })}>
                        {appState.stageError}
                    </div>
                ) : null}
            </div>
        );
    }
}
