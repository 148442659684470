import React, { Component } from 'react';
import cls from 'classnames';
import style from './textTransition.style.scss';
const isServer = !(typeof window !== 'undefined');

const TextTransition = !isServer ? require('react-text-transition').default : null;
const presets = !isServer ? require('react-text-transition').presets : null;
export type Props = {
    text: string;
    direction?: 'up' | 'down';
    inline?: boolean;
    delay?: number;
    springConfig?: 'default' | 'gentle' | 'wobbly' | 'stiff' | 'slow' | 'molasses';
    className?: string;
};
export default class TextTransitionComponent extends Component<Props> {
    getConfig() {
        if (!isServer) {
            return presets[this.props.springConfig || 'default'];
        } else { return null; }
    }
    render() {
        const { text, className, delay, inline, direction } = this.props;
        if (isServer) {
            return null;
        } else {
            return (
                <TextTransition
                    text={text}
                    className={className}
                    delay={delay}
                    inline={inline}
                    direction={direction}
                    springConfig={this.getConfig()} />);
        }
    }
}
